import React from 'react';
import Container from '../components/container';
import SEO from '../components/seo';
import { Col, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Title from '../Ui/Typography/Title';
import Text from '../Ui/Typography/Text';
import styled from 'styled-components';
import Section from '../components/Layout/Section';
import { media } from '../Ui/text-sizes';
import RightSmall from '../assets/right-small.png';
import Circle from '../assets/circle.png';

const PrivacyPolicy = () => {
    const { allMarkdownRemark } = useStaticQuery(graphql`
        query PrivacyPolicy {
            allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/privacy-policy/" } }) {
                edges {
                    node {
                        frontmatter {
                            title
                            description
                            id
                            img
                            logoBlack
                            slug
                        }
                    }
                }
            }
        }
`);

    const mainData = allMarkdownRemark.edges;

    return (
        <Container title='Legal information'>
            <SEO title='Legal information' />
            <Section spaceTop={1} mobileTopSpace={0} mobileBottomSpace={40}>
                <Row>
                    <Col xs={24} sm={{ span: 14, offset: 10 }} lg={{ span: 15, offset: 7 }}>
                        <Title level={2} color='darkGray' bottomSpace={40} mobileBottomSpace={20} letterMd>
                            Legal information
                    </Title>
                    </Col>
                </Row>
                <InfoSection>
                    <PrivacyPolicyBox xs={24} sm={10} lg={7}>
                        <Text level='t4' block bottomSpace={9} mobileBottomSpace={9}>
                            Privacy policy
                    </Text>

                    </PrivacyPolicyBox>
                    <Col xs={24} sm={14} lg={17}>
                        <Text level='t4'>
                            This Privacy Policy describes how Tidepool Labs LLC (“we”, “us” or “our”) collects and uses
                            the personal information you provide to us on our website:
                        <Link to='/'>
                                <Text level={4} colorType='primary' decoration='underline'> www.tidepoollabs.com </Text>
                            </Link>
                        (collectivelythe “Site”). It also describes the choices available to
                        you regarding our use of your personal information and how you can access
                        and update this information.
                    </Text>
                        <Text level='t4' spaceTop={35} mobileTopSpace={20} block>
                            Capitalized terms not defined in this Privacy Policy shall
                            have the meaning set forth in our Terms of Use.
                    </Text>
                        <Title level={3} color='darkGray' topSpace={35} bottomSpace={22} mobileBottomSpace={18}>
                            The Information We Collect
                    </Title>
                        <Text level='t4'>
                            Non-Personally Identifiable Information Collected. When You visit this Website,
                            we may use a web server and web server log to automatically track and collect certain
                            non-personally identifiable information, such as Your Internet protocol (IP) address,
                            referral pages and pages viewed, access times and other relevant statistics. Personally
                            Identifiable Information Collected. From time to time, this Website may provide opportunities
                            for You to submit personally identifiable information in order to register an account,
                            post information, or receive information You have requested, such as by registering to
                            receive services announcements, updates and notifications. The types of personally
                            identifiable information we may collect includes Your name, email address, and mailing
                            address. For users of Foundation, we will collect Your name, email address.
                    </Text>
                        <Title level={3} color='darkGray' topSpace={35} bottomSpace={22} mobileBottomSpace={18}>
                            Cookies
                    </Title>
                        <Text level='t4'>
                            A cookie is a string of information that a website stores on a visitor’s computer,
                            and that the visitor’s browser provides to the website each time the visitor returns.
                            We use cookies throughout the Website to help identify and track visitors, their usage
                            of our services, and their website access preferences. Visitors who do not wish to have
                            cookies placed on their computers should set their browsers to refuse cookies before
                            using our Website.
                    </Text>
                        <Title level={3} color='darkGray' topSpace={35} bottomSpace={22} mobileBottomSpace={18}>
                            How We Use Your Information
                    </Title>
                        <Text level='t4'>
                            The information collected via this Website is used to help improve and administer this
                            Website, provide information, help us to operate, improve, protect and develop our services
                            and business, and/or fulfill requests made by You. Please note that we may share Your personally
                            identifiable information with our affiliates or unaffiliated third parties who help us provide
                            such services or satisfy such requests, or with unaffiliated third parties with whom we have or
                            enter into a business relationship. We also may disclose Your personally identifiable information
                            (i) if we are required to do so by law or legal process (such as a court order), (ii) in response
                            to a request by law enforcement authorities, or (iii) when we believe disclosure is necessary or
                            appropriate to prevent physical harm or financial loss or in connection with an investigation of
                            suspected or actual illegal activity.
                        <Text level='t4' spaceTop={35} bottomSpace={15} mobileBottomSpace={32} mobileTopSpace={32} block>
                                If you provide us with personally identifiable information, You have the following
                                rights with respect to that personally identifiable information:
                        </Text>
                            <StyledList>
                                <li>
                                    To review the personally identifiable information that You have supplied to us
                            </li>
                                <li>
                                    To request that we correct any errors, outdated information, or omissions in the
                                    personally identifiable information that You have supplied to us
                            </li>
                                <li>
                                    To request that Your personally identifiable information not be used to contact You
                            </li>
                                <li>
                                    To request that Your personally identifiable information be removed from any
                                    solicitation list that we use
                            </li>
                                <li>
                                    To request that Your personally identifiable information be deleted from our records
                                    (with the exception of data collected through Foundation, which will not be deleted),
                                    To opt out of being solicited by this Website
                            </li>
                            </StyledList>
                        </Text>
                        <Title level={3} color='darkGray' topSpace={35} bottomSpace={22} mobileBottomSpace={18}>
                            Opting-Out
                    </Title>
                        <Text level='t4'>
                            If you have registered for product announcements, news, or updates, we will use Your
                            personally identifiable information to fulfill Your request. You may stop receiving any
                            electronic communications from us, including our newsletter, at any time by clicking on
                            and completing the opt-out link provided at the bottom of the applicable email.
                    </Text>
                        <Title level={3} color='darkGray' topSpace={35} bottomSpace={22} mobileBottomSpace={18}>
                            How We Protect Your Information
                    </Title>
                        <Text level='t4'>
                            The security of Your personally identifiable information is a priority for us and we take
                            reasonable precautions to protect it, which may, in certain circumstances, include the use of
                            Secure Sockets Layer (SSL) encryption technology supported by most Internet browsers.
                            Additionally, most Internet browsers also offer their own security features that enable
                            end users to control how their transmissions are protected. Please remember, however,
                            that any personally identifiable information You send to us via the Internet is subject to
                            the risk of unlawful interception by third parties, and that no method of safeguarding
                            information is 100% secure. Further, please note that, in our discretion, aggregated
                            information that contains no personally identifiable information may not be treated.
                    </Text>
                        <Title level={3} color='darkGray' topSpace={35} bottomSpace={22} mobileBottomSpace={18}>
                            Affiliated Company Websites
                    </Title>
                        <Text level='t4'>
                            To the extent this Website contains links to any networked web pages and/or other
                            websites operated by our partners and/or affiliates (each an “Affiliated Company
                            Website“), please check the individual Affiliated Company Website for its applicable
                            privacy policy, which may differ from this one, depending on the specific nature of
                            that site and the transactions taking place there. If an Affiliated Company Website
                            has its own privacy policy, the provisions of that policy will control, to the extent
                            of any conflict with.
                    </Text>
                        <Title level={3} color='darkGray' topSpace={35} bottomSpace={22} mobileBottomSpace={18}>
                            External Links
                    </Title>
                        <Text level='t4'>
                            For Your convenience, we may provide links from this Website to other sites that are external
                            to Human Ventures. We do not operate these external websites, have no control over them and
                            shall not be responsible for the privacy practices or contents of such external websites.
                            If You chose to visit any external websites, please familiarize Yourself with their privacy
                            policies and information collection practices. Your use of such external websites shall be
                            subject to the terms of use and privacy policies of such external.
                    </Text>
                    </Col>
                </InfoSection>
            </Section>
        </Container>
    )
};

const StyledList = styled.ul`
    list-style-image: url(${Circle});
`;

const InfoSection = styled(Row)`
    ${media.down('sm')} {
        flex-direction: column-reverse;
    }
`;

const PrivacyPolicyBox = styled(Col)`
    ${media.down('sm')} {
        margin-top: 38px;
    }
`;

export default PrivacyPolicy;
