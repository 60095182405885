import React from 'react';
import { Row } from 'antd';
import styled from 'styled-components';
import { media } from '../../Ui/text-sizes';
import Colors from '../../Ui/color';
import '../hero.less';
const Section = ({ children, className = '', secondary = false, gradientBg = false, space = false, spaceZero = false, spaceBottom = 0, bottomdark = false, spaceTop = 0, align = 'left', mobileBottomSpace = 50, mobileTopSpace = 50 }) => {
    return <R className={className} align={align} secondary={secondary} gradientBg={gradientBg} space={space} spaceZero={spaceZero} spacebottom={spaceBottom} bottomdark={bottomdark} spacetop={spaceTop} mobileBottomSpace={mobileBottomSpace} mobileTopSpace={mobileTopSpace}>
        <div className={'container-hero'} style={{ textAlign: align }}>
            {children}
        </div>
    </R>
};

const R = styled(Row)`
    ${props => props.secondary === true && `
        background-color: ${Colors.offWhite};
    `}
    
    ${props => props.gradientBg === true && `
        background: ${Colors.gradient5};
        padding-bottom: 0px !important;
    `}

    ${props => props.space === true && `
        background-color: ${Colors.white};
        padding-bottom: 75px !important;
        ${media.down('sm')} {
            padding-bottom: 15px !important;
        }
    `}

    ${props => props.spaceZero === true && `
    background-color: ${Colors.white};
    padding-bottom: 0px !important;
`}
    
    padding-top: 30px;
    padding-bottom: 120px;
    
    ${props => props.spacebottom > 0 && `
        padding-bottom: ${props.spacebottom}px;
    `}

    
    ${props => props.bottomdark === true && `
        background-color: ${Colors.lightBlack};
        text-align: center;
         ${media.down('sm')} {
            //margin-top: 0px !important;
            text-align: center;
        }
    `}
    
     ${props => props.spacetop > 0 && `
        padding-top: ${props.spacetop}px;
    `}
    
    ${media.down('sm')} {
        padding-bottom: ${props => `${props.mobileBottomSpace}px`};
        padding-top: ${props => `${props.mobileTopSpace}px`};
    }
`;
export default Section;
